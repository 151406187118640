import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Pages from '../../components/Pages';
import * as pageActions from '../../actions/pages';

function mapStateToProps(state) {
  return state.pages;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(pageActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pages);
