import {
  STATUS_SEARCH_RESET,
  STATUS_SEARCH_PAGE_NEXT,
  STATUS_SEARCH_PAGE_LOAD_START,
  STATUS_SEARCH_PAGE_LOAD_END
} from '../actions/status'

import { makeDateTime } from '../services/date'

const initialState = {
  statuses: false,
  page: 0,
  loading: false
}

export default function status (state = initialState, action) {
  switch (action.type) {
    case STATUS_SEARCH_PAGE_LOAD_END:
      return {
        ...state,
        statuses: ((state.statuses) ? state.statuses : []).concat(
          action.statuses.map(status => (
            { ...status,
              created_at: makeDateTime(status.created_at) }))
        ),
        loading: false
      }

    case STATUS_SEARCH_PAGE_LOAD_START:
      return {
        ...state,
        loading: true
      }
    
    case STATUS_SEARCH_PAGE_NEXT:
      return {
        ...state,
        page: state.page + 1
      }

    case STATUS_SEARCH_RESET:
      return initialState;

    default:
      return state;
  }
}