import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TopicList from '../../components/TopicList';
import * as topicActions from '../../actions/topic';

function mapStateToProps(state) {
  return state.topic;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...topicActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicList);
