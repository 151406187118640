import {
  HASHTAG_SEARCH_END,
  HASHTAG_SEARCH_START,
  HASHTAG_SEARCH_QUERY_SET,
  HASHTAG_SEARCH_QUERY_RESET
} from '../actions/hashtagSearch'

const stateIdle = 'IDLE'
const stateSearching = 'SEARCHING';

const  initialState = {
  query: '#Amazonia',
  hashtags: [],
  state: stateIdle
}

export default function hashtagSearch (state = initialState, action) {
  switch (action.type) {
    case HASHTAG_SEARCH_END:
      return {
        ...state,
        state: stateIdle,
        hashtags: action.hashtags
      }

    case HASHTAG_SEARCH_START:
      return {
        ...state,
        state: stateSearching
      }

    case HASHTAG_SEARCH_QUERY_SET:
      return {
        ...state,
        query: action.query
      }

    case HASHTAG_SEARCH_QUERY_RESET:
      return {
        ...state,
        query: '',
        hashtags: []
      }

    default:
      return state;
  }
}