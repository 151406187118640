export function triggerDownload (content, name, mime) {
  const a = document.createElement('a');
  a.setAttribute('href', `data:${mime};charset=utf-8,${encodeURIComponent(content)}`);
  a.setAttribute('download', name);
  
  if (document.createEvent) {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    a.dispatchEvent(event);
  }
  else {
      a.click();
  }
}