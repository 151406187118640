import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Exporter from '../../components/Exporter';
import * as exporterActions from '../../actions/exporter';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(exporterActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exporter);
