import React from 'react';
import { messages } from  '../messages';
import { AS_INSTALLATION } from '../settings';

export const HashtagFilter = ({ hashtags, coOccurence, mode, hashtagFilterRemove, hashtagFilterReset, hashtagFilterModeSet  }) => (
  <section className="control--panel hashtag-filter" { ...{ title: (AS_INSTALLATION) ? null : messages.hashtagFilter.tooltip } }>
    { hashtags.length > 0 &&
      <>
        <div>
          Found { coOccurence.count } tweets using
          <select value={ mode } onChange={ (e) => hashtagFilterModeSet(e.target.value) }>
            <option value="or">at least one (or)</option>
            <option value="and">all (and)</option>
          </select>
          of these hashtags: 
        </div>
        <section className="hashtag-filter--list" data-filter-mode={ mode }>
          <div className="hashtag-filter--list-wrapper">
            { hashtags.map((hashtag) => <section key={ hashtag.id } className="hashtag-filter--list--hashtag">
                #{ hashtag.text } <span className="inline-control" { ...{ title: (AS_INSTALLATION) ? null : "Remove hashtag from filter" } } onClick={ () => hashtagFilterRemove(hashtag) }>X</span>
            </section> ) }
            { hashtags.length > 1 &&
              <button onClick={ () => hashtagFilterReset() }>Remove all</button> }
          </div>
        </section>
      </> }
  </section>
)

export default HashtagFilter