import React, { createRef } from 'react';
import * as d3 from 'd3';
import { messages } from '../messages';
import { AS_INSTALLATION } from '../settings';


// Data Array<{ date: Date, count: integer }>
function barchart (data, timeFilter, svgEl, timeFilterSet) {
  console.log('Frequency graph being rendered');
  if (svgEl) {
    if (data.length > 0) {

      const clientRect = svgEl.parentElement.getBoundingClientRect(),
            width = clientRect.width,
            height = clientRect.height,
            margin = ({ top: 0, right: 0, bottom: 20, left: 20 }),
            start = data[0].date,
            end = data[data.length - 1].date,
            days = d3.timeDay.count(start, end),
            interval = d3.timeMonth,
            resolution = d3.timeDay;

            
      var y = d3.scaleLinear()
        .domain([0, Math.max(50, d3.max(data, d => d.count))])
        .range([height - margin.bottom, margin.top])
            
      var x = d3.scaleTime()
        .domain([start, end])
        .range([margin.left, width - margin.right])
        // .rangeRound([margin.left, width - margin.right])
        // .padding(0.1)
 

      function brushing (event) {
        if (event) {
          if (!event.sourceEvent || event.sourceEvent.type === "brush") return;
          const d0 = event.selection.map(x.invert); // Array<Date, Date>
          const d1 = d0.map(resolution.round); // Array<Date, Date>

          // If empty when rounded, use floor instead.
          if (d1[0] >= d1[1]) {
            d1[0] = interval.floor(d0[0]);
            d1[1] = interval.offset(d1[0]);
          }
          
          // d1 is an array of two dates
          // the 'map' call maps the values in d1 on the x-axis ?
          d3.select(this).call(brush.move, d1.map(x));
        }
      }

      function brushended (event) {
        if (event && event.sourceEvent) {
          // Make sure the brushend occured because of a mouse
          // event to avoid an infinite loop.
          const d0 = event.selection.map(x.invert);
          timeFilterSet(d0[0], d0[1]);
        }
      }
      
      let brush = d3.brushX()
        .extent([[margin.left, margin.top], [width - margin.right, height - margin.bottom]])
        .on("brush", brushing)
        .on("end", brushended);

      if (AS_INSTALLATION) {
        brush = brush.touchable(() => true);
      }

      var yAxis = g => g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call(g => g.select(".domain").remove())
    
      var xAxis = g => g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(d3.timeMonth).tickSizeOuter(0))
    
      const svg = d3.select(svgEl)
          .attr("viewBox", [0, 0, width, height]);
      
      svg.selectAll('*').remove();

      svg.append("g")
          .call(xAxis);
    
      svg.append("g")
          .call(yAxis);
    
      const brushGroup = svg.append("g");
          brushGroup.call(brush);

      const tooltipDateFormatter = d3.timeFormat("%d-%m-%Y");

      const tooltip = svg.append("g")
        .attr("class", "tooltip")
        .style("display", "none");
      
      const barWidth = Math.floor(((width - margin.right) - margin.left) / days) - 1;

      svg.append("g")
        .attr("fill", "#1c1c1c")
        .selectAll("rect")
        .data(data)
        .join("rect")
          .attr("x", d => x(d.date))
          .attr("y", d => y(d.count))
          .attr("height", d => y(0) - y(d.count))
          .attr("width", barWidth)
          .on("mouseover", (e, d) => {
            tooltip.style('display', 'initial');
            tooltip.select('text').text(tooltipDateFormatter(d.date) + ' (' + d.count + ')');
            tooltip.attr("transform", `translate(${x(d.date) + .5 * barWidth},${ height - margin.bottom })`);
            // tooltip.attr('x', x(d.date));
            // tooltip.attr('y', y(d.count));
          })
          .on("mouseout", () => {
            tooltip.style('display', 'none')
          });
    
      tooltip.append("rect")
        .attr("width", 100)
        .attr("x", -50)
        .attr("y", 1)
        .attr("height", 20)
        .style("z-index", 1)
        .attr("fill", "#1c1c1c");
      tooltip.append("text")
        .attr("x", 0)
        .attr("y", 17)
        .style("text-anchor", "middle")
        .attr("font-size", ".7rem")
        .style("z-index", 1)  
        .attr("fill", "#deffd9");

      // if the timefilter is set externally set it.
      if (timeFilter.start != null) {
        brush.move(brushGroup, [x(timeFilter.start), x(timeFilter.end)]);
      }
    } else {
      d3.select(svgEl).selectAll('*').remove()
    }
  }
}


export class FrequencyGraph extends React.Component {
  constructor(props) {
    super(props);
    this.graphRef = createRef()
  }
  
  componentDidMount = () => {
    this.props.hashtagFrequency();
    window.addEventListener('resize', this.props.hashtagFrequency);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.props.hashtagFrequency);
  }
  
  render = () => {
    barchart(this.props.frequency, this.props.timeFilter, this.graphRef.current, this.props.timeFilterSet);
    return <section id="frequency-graph" { ...{ title: (AS_INSTALLATION) ? null : messages.frequencyGraph.tooltip } }><svg ref={ this.graphRef }></svg></section>
  }
}

export default FrequencyGraph