import React from 'react';
import { formatDateTime } from '../services/date';
import { messages } from '../messages';
import { AS_INSTALLATION } from '../settings';

export class StatusList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount () {
    if (!this.props.statuses) {
      this.props.statusSearchPageNext();
    }
  }

  render = () => {
    return <section className="status--list" { ...{ title: (AS_INSTALLATION) ? null : messages.statusList.tooltip } }>
        { this.props.statuses &&
          <>
          { this.props.statuses.map((status) => <section key={ status.id } className="status--list--status">
            <strong>{ status.author_name }</strong> ― { formatDateTime(status.created_at) }
            { AS_INSTALLATION &&
              <p dangerouslySetInnerHTML={ { __html: status.text } }></p> }
            { !AS_INSTALLATION &&
              <p dangerouslySetInnerHTML={ { __html: status.text.replace(/(https?:\/\/[\S]+)/g, '<a href="$1" target="_blank">$1</a>') } }></p> }
          </section> )}
          { !this.props.loading && <button onClick={ this.props.statusSearchPageNext }>Load more tweets...</button> }
          </>
        }
      </section>
  }
}

export default StatusList