import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import StatusList from '../../components/StatusList';
import * as statusActions from '../../actions/status';

function mapStateToProps(state) {
  return state.status;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(statusActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusList);
