import {
  TOPIC_LOAD_END,
  TOPIC_LOAD_START,
  TOPIC_SELECT,
  TOPIC_CLEAR
} from '../actions/topic'

/**
 * 
 * 
 */

const TOPIC_INITIAL_SELECTED = null;

const initialState = {
  topics: null, // null | [ { id: int, title: str, hashtags: [ { id: int, url: str, text: str }, ... ] }, ... ]
  loading: false, // boolean
  selected: null // selected topic
}

export default function topic (state = initialState, action) {
  switch (action.type) {
    case TOPIC_LOAD_END:
      return {
        ...state,
        topics: action.topics,
        loading: false
      }

    case TOPIC_LOAD_START:
      return {
        ...state, 
        loading: true
      }

    case TOPIC_SELECT:
      return {
        ...state,
        selected: action.topic,
      }

    case TOPIC_CLEAR:
      return {
        ...state,
        selected: TOPIC_INITIAL_SELECTED
      }

    default:
      return state;
  }
}