import {
  ACTIVE_GRAPH_SET,
  ACTIVE_PANEL_SET
} from '../actions/app'

export const GRAPH_COOCCURENCE = 0;
export const GRAPH_NETWORK = 1;

/**
 * state = {
 *  activeGraph: enum<int>
 *  activePanel: str
 * }
 */

const initialState = {
  activeGraph: GRAPH_COOCCURENCE,
  activePanel: null,
}

export default function network (state = initialState, action) {
  switch (action.type) {
    case ACTIVE_GRAPH_SET:
      return {
        ...state,
        activeGraph: (action.graph == GRAPH_NETWORK) ? GRAPH_NETWORK : GRAPH_COOCCURENCE
      }

    case ACTIVE_PANEL_SET:
      return {
        ...state,
        activePanel: action.panel
      }

    default:
      return state;
  }
}