import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HashtagSearch from '../../components/HashtagSearch';
import * as hashtagSearchActions from '../../actions/hashtagSearch';
import { hashtagFilterAdd } from '../../actions/hashtagFilter';

function mapStateToProps(state) {
  return state.hashtagSearch;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...hashtagSearchActions, hashtagFilterAdd }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HashtagSearch);
