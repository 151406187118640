import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showPage, loadPageList } from '../../actions/pages';
import Navbar from '../../components/Navbar';

function mapStateToProps(state) {
  return { pages: state.pages.pages };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ showPage, loadPageList }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
