import React from 'react';
// import arrow from '../static/arrow-down.svg';
// import arrowHover from '../static/arrow-down--hover.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'


export class Exporter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  toggle = () => {
    this.setState({ expanded: !(this.state.expanded) });
  }

  render = () => (
    <div className="exporter" { ... { 'data-expanded': this.state.expanded } }>
      <button onClick={ () => this.toggle() }>
        {/* <img src={ arrow } class="icon icon--normal" />
        <img src={ arrowHover } class="icon icon--hover" /> */}
        <FontAwesomeIcon icon={ faArrowDown } />
      </button>
      <section className="exporter--background" onClick={ (e) => { if (e.target == e.currentTarget) { this.toggle(); } } }></section>
      <div className="exporter--actions">
        <button onClick={ () => { this.props.exportFrequencies(); this.toggle(); } }>Export Frequencies JSON</button>
        <button onClick={ () => { this.props.exportCoOccurences(); this.toggle(); } }>Export Co-Occurences JSON</button>
        <button onClick={ () => { this.props.exportGeoJSON(); this.toggle(); } }>Export GeoJSON</button>
        {/* <button onClick={ exportPolygonJSON }>Export Polygon JSON</button> */}
        <button onClick={ () => { this.props.exportCSV(); this.toggle(); } }>Export CSV</button>
        {/* <button onClick={ exportSTL }>Export STL (Multiple Solids)</button> */}
        <button onClick={ () => { this.props.exportSTLOneSolid(); this.toggle(); } }>Export STL</button>
      </div>
    </div>
  )
}

export default Exporter;