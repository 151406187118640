import {
  TIMEFILTER_CLEAR,
  TIMEFILTER_SET
} from '../actions/timeFilter'

const initialState = {
  start: null, // null | Date
  end: null // null | Date
}



export default function timeFilter (state = initialState, action) {
  switch (action.type) {
    case TIMEFILTER_SET:
      return {
        ...state,
        start: action.start,
        end: action.end
      }

    case TIMEFILTER_CLEAR:
      return {
        ...state,
        start: null,
        end: null
      }

    default:
      return state;
  }
}