import { hashtagNetwork as hashtagNetworkAPI } from '../services/api';
import { AS_INSTALLATION } from '../settings';

export const HASHTAG_NETWORK_LOAD_START = 'HASHTAG_NETWORK_LOAD_START'
export const HASHTAG_NETWORK_LOAD_END = "HASHTAG_NETWORK_LOAD_END";

export const hashtagNetwork = () => (dispatch, getState) => {
  dispatch({ type: HASHTAG_NETWORK_LOAD_START })
  hashtagNetworkAPI(getState().hashtagFilter.hashtags, getState().timeFilter.start, getState().timeFilter.end, getState().hashtagFilter.mode)
    .then((data) => dispatch({ type: HASHTAG_NETWORK_LOAD_END, hashtags: data.hashtags, links: data.links }))
    .catch(() => {
      dispatch({ type: HASHTAG_NETWORK_LOAD_END, hashtags: [], links: [] });
      if (AS_INSTALLATION) {
        window.setTimeout(() => hashtagNetwork(dispatch, getState), 1000);
      }
    })
};
