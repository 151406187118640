export const ACTIVE_GRAPH_SET = 'ACTIVE_GRAPH_SET';

export const ACTIVE_PANEL_SET = 'ACTIVE_PANEL_SET';

export const activeGraphSet = (graph) => ({
  type: ACTIVE_GRAPH_SET,
  graph: graph
})

export const activePanelSet = (panel) => ({
  type: ACTIVE_PANEL_SET,
  panel: panel
})

export const activePanelClose = () => ({
  type: ACTIVE_PANEL_SET,
  panel: null
})