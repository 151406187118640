import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TimeFilter from '../../components/TimeFilter';
import * as timeFilterActions from '../../actions/timeFilter';

function mapStateToProps(state) {
  return state.timeFilter;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(timeFilterActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeFilter);
