import { hashtagSearch as hashtagSearchAPI } from '../services/api'
import HashtagSearch from '../components/HashtagSearch';

export const HASHTAG_SEARCH_QUERY_SET = "HASHTAG_SEARCH_QUERY_SET";
export const HASHTAG_SEARCH_QUERY_RESET = "HASHTAG_SEARCH_QUERY_RESET";
export const HASHTAG_SEARCH_START = "HASHTAG_SEARCH_START";
export const HASHTAG_SEARCH_END = "HASHTAG_SEARCH_END";


export const hashtagSearch = (query) => (dispatch, getState) => {
  dispatch({ type: HASHTAG_SEARCH_START })
  let startswith = 0;

  if (query && query.startsWith('#')) {
    query = query.substr(1);
    startswith = 1;
  }

  hashtagSearchAPI(query, startswith)
    .then((data) => dispatch({ type: HASHTAG_SEARCH_END, hashtags: data.results}) )
    .catch(() => dispatch({ type: HASHTAG_SEARCH_END, hashtags: [] }))
};

export const hashtagSearchQuerySet = (query) => (dispatch) => {
  dispatch({
    type: HASHTAG_SEARCH_QUERY_SET,
    query: query
  })

  if (query && query.length > 3) {
    dispatch(hashtagSearch(query));
  }
}

export const hashtagSearchQueryReset = () => (dispatch) => {
  dispatch({ type: HASHTAG_SEARCH_QUERY_RESET })
}