import { hashtagCoOccurence as hashtagCoOccurenceAPI } from '../services/api';
import { AS_INSTALLATION } from '../settings';

export const COOCCURENCE_LOAD_START = 'COOCCURENCE_LOAD_START'
export const COOCCURENCE_LOAD_END = "COOCCURENCE_LOAD_END";
export const COOCCURENCE_GRAPHSIZE_SET = "COOCCURENCE_GRAPHSIZE_SET"
export const COOCCURENCE_GRAPH_POLYGONS_SET = "COOCCURENCE_GRAPH_POLYGONS_SET"
export const COOCCURENCE_GRAPH_CLIPPATH_SET = "COOCCURENCE_GRAPH_CLIPPATH_SET"
export const COOCCURENCE_GRAPH_CLIPPATH_CLEAR = "COOCCURENCE_GRAPH_CLIPPATH_CLEAR"

export const hashtagCoOccurence = () => (dispatch, getState) => {
  dispatch({ type: COOCCURENCE_LOAD_START })
  const state = getState(),
        hashtags = state.hashtagFilter.hashtags,
        start = state.timeFilter.start,
        end = state.timeFilter.end,
        mode = state.hashtagFilter.mode;

  hashtagCoOccurenceAPI(hashtags, start, end, mode)
    .then((data) => dispatch({ type: COOCCURENCE_LOAD_END, hashtags: data.results, count: data.count }))
    .catch(() => {
      dispatch({ type: COOCCURENCE_LOAD_END, hashtags: [], count: null });
      if (AS_INSTALLATION) {
        window.setTimeout(() => hashtagCoOccurence(dispatch, getState), 1000);
      }
    })
};


export const coOccurenceGraphsizeSet = (graphsize) => ({
  type: COOCCURENCE_GRAPHSIZE_SET,
  graphsize: graphsize
})

export const coOccurenceGraphPolygonsSet = (polygons) => ({
  type: COOCCURENCE_GRAPH_POLYGONS_SET,
  polygons: polygons
});

export const coOccurenceGraphClipPathSet = (clipPathKey) => (dispatch, getState) => {
  let clipPaths = getState().coOccurence.clipPaths,
      clipPath;

  if (clipPathKey > -1 && clipPathKey < clipPaths.length) {
    clipPath = clipPaths[clipPathKey];

    dispatch ({
      type: COOCCURENCE_GRAPH_CLIPPATH_SET,
      clipPathKey,
      clipPath
    });
  } else {
    dispatch(coOccurenceGraphClipPathClear());
  }
};

export const coOccurenceGraphClipPathClear = () => ({ type: COOCCURENCE_GRAPH_CLIPPATH_CLEAR });