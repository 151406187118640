import React, { createRef } from 'react';
import * as d3 from 'd3';
import * as cola from 'webcola';
import { GRAPH_NETWORK } from '../reducers/app';
// import { precisionFixed } from 'd3';
import { messages } from '../messages';
import { AS_INSTALLATION } from '../settings';

function networkGraph (hashtags, links, svgEl, hashtagFilterAdd) {
  if (svgEl) {
    if (hashtags.length > 0) {
      console.log('Starting network draw')
      var clientRect = svgEl.parentElement.getBoundingClientRect();

      const width = clientRect.width;
      const height = clientRect.height;
      const svg = d3.select(svgEl)
              .attr("viewBox", [0, 0, width, height])
              .style("font", "10px");
      svg.selectAll('*').remove();
      
      // Transformation of data into a object with nodes and links
      const nodes = hashtags.map(h => ({ 'id': h.id, 'label': h.text, width: h.text.length * 8, height: 14 }));
      const index = new Map(nodes.map(d => [d.id, d]));
      links = links.map(d => ({
          source: index.get(d.source),
          target: index.get(d.target)
        }));

      // Setup of the graph
      const layout = cola.d3adaptor(d3)
        .size([width, height])
        .nodes(nodes)
        .links(links)
        .symmetricDiffLinkLengths(17.5)
        .avoidOverlaps(true)
        .start(30, 15, 20);


      const link = svg.append("g")
        .attr("stroke-width", 1)
        .attr("stroke", "#deffd9")
        .selectAll("line")
        .data(links)
        .join("line")


      const node = svg.append("g")
        .selectAll("rect")
        .data(nodes)
        .enter()
        .append("rect")
        .attr("fill", "#1c1c1c")
        // .attr("stroke-width", .25)
        // .attr("stroke", "#000")
        .attr("width", d => d.width)
        .attr("height", d => d.height);


      const label = svg.append("g")
        .selectAll("text")
        .data(nodes)
          .join("text")
          .attr("class", "graph--label network--graph--label")
          .attr("fill", "#deffd9")
          .attr("text-anchor", "middle")
          .text(d => '#' + d.label)
          .on("click", function (e, d) {
            hashtagFilterAdd({
              id: d.id,
              text: d.label
            });
          });


      layout.on("tick", () => {
        link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);

        node
            .attr("x", function (d) { return d.x - d.width / 2; })
            .attr("y", function (d) { return d.y - d.height / 2; });

        label
            .attr("x", d => d.x)
            .attr("y", d => d.y + 2);
      });


      console.log('End network draw')
    } else {
      d3.select(svgEl).selectAll('*').remove()
    }
  }
}

export class Network extends React.Component {
  constructor(props) {
    super(props)
    this.graphRef = createRef()
  }

  shouldComponentUpdate (nextProps) {
    if (this.props.hashtags.length != nextProps.hashtags.length
      || this.props.links.length != nextProps.links.length
      ) {
      return true;
    }
    return false
  }

  drawGraph () {
    networkGraph(this.props.hashtags, this.props.links, this.graphRef.current, this.props.hashtagFilterAdd);
  }

  componentDidMount () {
    this.drawGraph();
    window.addEventListener('resize', this.drawGraph.bind(this));
  }
  
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.drawGraph.bind(this));
  }

  render () {
    return <svg id="cooccurence--network--graph" ref={ this.graphRef }></svg>
  }
}

export class NetworkGraph extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.hashtagNetwork(this.props.hashtagFilter.hashtags);
  }

  render = () => {
    if (this.props.activeGraph == GRAPH_NETWORK) {
      if (this.props.loading) {
        return <section id="cooccurence--network--graph--container" { ...{ title: (AS_INSTALLATION) ? null : messages.networkGraph.tooltip } } data-loading>Loading network</section>;
      } else {
        return <section id="cooccurence--network--graph--container" { ...{ title: (AS_INSTALLATION) ? null : messages.networkGraph.tooltip } }>
          <Network hashtags={ this.props.hashtags } links={ this.props.links } graphsize={ 100 } hashtagFilterAdd={ this.props.hashtagFilterAdd } />
        </section>
      }
    } else {
      return <></>
    }
  } 
}

export default NetworkGraph