import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FrequencyGraph from '../../components/FrequencyGraph';
import * as frequencyActions from '../../actions/frequency';
import { timeFilterSet } from '../../actions/timeFilter';

function mapStateToProps(state) {
  return {
    frequency: state.frequency.frequency,
    timeFilter: state.timeFilter
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...frequencyActions, timeFilterSet }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrequencyGraph);
