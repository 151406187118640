import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActiveGraphSwitch from '../../components/ActiveGraphSwitch';
import { activeGraphSet } from '../../actions/app';

function mapStateToProps(state) {
  return {
    activeGraph: state.app.activeGraph
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ activeGraphSet }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveGraphSwitch);
