import { hashtagCoOccurence } from './coOccurence';
import { hashtagFrequency } from './frequency';
import { hashtagNetwork } from './network';
import { statusSearchReset, statusSearchPageNext } from './status';
import { topicClear } from './topic';

export const HASTAG_FILTER_ADD = 'ACTIVE_HASHTAGS_ADD'
export const HASHTAG_FILTER_REMOVE = "ACTIVE_HASHTAGS_REMOVE";
export const HASHTAG_FILTER_SET = "ACTIVE_HASHTAGS_SET";
export const HASHTAG_FILTER_CLEAR = "ACTIVE_HASHTAGS_CLEAR";
export const HASHTAG_FILTER_RESET = "ACTIVE_HASHTAGS_RESET";
export const HASHTAG_FILTER_CHANGED = "ACTIVE_HASHTAGS_CHANGED";
export const HASHTAG_FILTER_MODE_SET = "HASHTAG_FILTER_MODE_SET"

export const hashtagFilterAdd = (hashtag) => (dispatch, getState) => {
  dispatch({
    type: HASTAG_FILTER_ADD,
    hashtag: hashtag
  })

  dispatch(hashtagFilterChanged());
  // dispatch({ type: ACTIVE_HASHTAGS_CHANGED })
};

export const hashtagFilterRemove = (hashtag) => (dispatch, getState) => {
  dispatch({
    type: HASHTAG_FILTER_REMOVE,
    hashtag: hashtag
  })

  dispatch(hashtagFilterChanged());
  // dispatch({ type: ACTIVE_HASHTAGS_CHANGED })
  // dispatch(hashtagCoOccurence(getState().activeHashtags.hashtags))
  // dispatch()
};

export const hashtagFilterSet = (hashtags, mode) => (dispatch) => {
  dispatch({
    type: HASHTAG_FILTER_SET,
    hashtags: hashtags
  })
  if (mode) {
    dispatch({
      type: HASHTAG_FILTER_MODE_SET,
      mode: mode
    })
  } 
  dispatch(hashtagFilterChanged());

  // dispatch(hashtagCoOccurence(getState().activeHashtags.hashtags))
  // dispatch({ type: ACTIVE_HASHTAGS_CHANGED })
};


export const hashtagFilterReset = () => (dispatch, getState) => {
  dispatch({
    type: HASHTAG_FILTER_RESET
  })
  topicClear()(dispatch, getState);
  dispatch(hashtagFilterChanged());
};

// export const hashtagFilterClear = () => (dispatch, getState) => {
//   dispatch({
//     type: HASHTAG_FILTER_CLEAR
//   })
//   dispatch(hashtagFilterChanged());
// };

export const hashtagFilterChanged = () => (dispatch) => {
  dispatch(hashtagFrequency());
  dispatch(hashtagNetwork());
  dispatch(hashtagCoOccurence());
  dispatch(statusSearchReset());
  dispatch(statusSearchPageNext());
  dispatch({ type: HASHTAG_FILTER_CHANGED });
}

export const hashtagFilterModeSet = (mode) => (dispatch) => {
  dispatch({
    type: HASHTAG_FILTER_MODE_SET,
    mode: mode
  });
  dispatch(hashtagFilterChanged());
};