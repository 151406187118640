import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export const Pages = ({ activePage, pages, closePage }) => {
  if (activePage !== null) {
    const page = pages.find((page) => page.id == activePage);
    return <section className="panel" onClick={ (e) => { if (e.target == e.currentTarget) { closePage(); } } }>
      <section class="panel--content">
        <FontAwesomeIcon icon={ faTimes } className="panel--close" size="lg" onClick={ closePage } />
        { (page) ? <div dangerouslySetInnerHTML={ { '__html': page.content } }></div> : <p>Loading...</p>}
      </section>
    </section>
  }
  else {
    return <></>
  }
};

export default Pages;