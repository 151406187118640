import { statusSearch } from '../services/api';
import { AS_INSTALLATION } from '../settings';

export const STATUS_SEARCH_RESET = "STATUS_SEARCH_SET";
export const STATUS_SEARCH_PAGE_NEXT = "STATUS_SEARCH_PAGE_NEXT";
export const STATUS_SEARCH_PAGE_LOAD_START = "STATUS_SEARCH_PAGE_LOAD_START";
export const STATUS_SEARCH_PAGE_LOAD_END = "STATUS_SEARCH_PAGE_LOAD_END";

export const statusSearchReset = () => ({ type: STATUS_SEARCH_RESET });

export const statusSearchPageNext = () => (dispatch, getState) => {
  dispatch({ type: STATUS_SEARCH_PAGE_NEXT })
  dispatch({ type: STATUS_SEARCH_PAGE_LOAD_START })

  const state = getState(),
        hashtags = state.hashtagFilter.hashtags,
        start = state.timeFilter.start,
        end = state.timeFilter.end,
        mode = state.hashtagFilter.mode,
        page = state.status.page;

  statusSearch(hashtags, start, end, mode, page)
    .then((data) => dispatch({ type: STATUS_SEARCH_PAGE_LOAD_END, statuses: data.results }))
    .catch(() => {
      dispatch({ type: STATUS_SEARCH_PAGE_LOAD_END, statuses: [] })
      
      if (page == 0 && AS_INSTALLATION) {
        window.setTimeout(() => statusSearchPageNext(dispatch, getState), 1000);
      }
    })
};
