import React from 'react';
import { messages } from  '../messages';
import { AS_INSTALLATION } from '../settings';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

// { query, hashtags, state, hashtagSearch, hashtagSearchQuerySet, hashtagSearchQueryReset, hashtagFilterAdd }) => (

export class HashtagSearch extends React.Component {
  constructor(props) {
    super(props);
    this.keyboardRef = React.createRef();
  }

  state = {
    keyboardOpen: false
  }

  keyboardLayout = {'default': [
    '1 2 3 4 5 6 7 8 9 0 - _ = {bksp}',
    'q w e r t y u i o p [ ] \\',
    'a s d f g h j k l ; \'',
    'z x c v b n m , . /',
    '# {space} ! @ & {close}' ] };

  onKeyPress = (button) => {
    if (button === "{close}") this.closeKeyboard();
  }
  
  closeKeyboard = () => {
    this.setState({ keyboardOpen: false });
  }

  openKeyboard = () => {
    this.setState({ keyboardOpen: true });
  }

  render = () => (
    <section className="hashtag-search" { ...{ title: (AS_INSTALLATION) ? null : messages.hashtagSearch.tooltip } }>
      {AS_INSTALLATION && <div id="hashtag-search--onscreen-keyboard" data-open={ this.state.keyboardOpen } >
        <Keyboard 
          layout={ this.keyboardLayout }
          keyboardRef={ ref => (this.keyboardRef.current = ref) }
          onChange={ (value) => this.props.hashtagSearchQuerySet(value) }
          onKeyPress={ this.onKeyPress }
          onInit={ (keyboard) => keyboard.setInput(this.props.query) } 
          display={ { '{close}': 'close', '{space}': ' ', '{bksp}': 'backspace' } } /></div>}
      <input type="text" className="hashtag-search--input" 
        value={ this.props.query }
        onFocus={ this.openKeyboard }
        onChange={ (e) => {
          if (AS_INSTALLATION) {
            this.keyboardRef.current.setInput(e.target.value);
          }
          this.props.hashtagSearchQuerySet(e.target.value); 
        } } />
      {/* <span className="button" onClick={ () => hashtagSearchQueryReset() }>Reset</span> */}
      <span className="button"
        onClick={ () => {
          this.closeKeyboard();
          this.props.hashtagSearch(this.props.query);
        } }>Search</span>
      <section className="hashtag-search--suggestions">
        { this.props.hashtags.map((hashtag) => 
            <section key={ hashtag.id }
              onClick={ () => {
                this.closeKeyboard();
                this.props.hashtagFilterAdd(hashtag);
              } } 
              className="hashtag-search--suggestions--suggestion">
            #{ hashtag.text }
        </section> ) }
      </section>
    </section>
  )
}


export default HashtagSearch