import { hashtagCoOccurence } from './coOccurence'
import { hashtagNetwork } from './network'
import { statusSearchReset, statusSearchPageNext } from './status';


export const TIMEFILTER_SET = 'TIMEFILTER_SET';
export const TIMEFILTER_CLEAR = 'TIMEFILTER_CLEAR';

/**
 * sets start and end of the time filter
 * @param {Date} start low bound of the time filter
 * @param {Date} end High bound of the time filter
 */
export const timeFilterSet = (start, end) => (dispatch) => {
  dispatch({ type: TIMEFILTER_SET, start, end })
  dispatch(hashtagCoOccurence())
  dispatch(hashtagNetwork())
  dispatch(statusSearchReset());
  dispatch(statusSearchPageNext());
};

export const timeFilterClear = () => (dispatch) => {
  dispatch({ type: TIMEFILTER_CLEAR })
  dispatch(hashtagCoOccurence())
  dispatch(hashtagNetwork())
  dispatch(statusSearchReset());
  dispatch(statusSearchPageNext());
}
