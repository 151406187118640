import { topicList as topicListAPI } from '../services/api';
import { AS_INSTALLATION } from '../settings';
import { hashtagFilterSet,  } from './hashtagFilter';
import { coOccurenceGraphClipPathSet, coOccurenceGraphClipPathClear } from './coOccurence';

export const TOPIC_LOAD_START = 'TOPIC_LOAD_START'
export const TOPIC_LOAD_END = "TOPIC_LOAD_END";
export const TOPIC_SELECT = "TOPIC_SELECT";
export const TOPIC_CLEAR = "TOPIC_CLEAR";

export const topicList = () => (dispatch, getState) => {
  dispatch({ type: TOPIC_LOAD_START })
  topicListAPI()
    .then((data) => dispatch({ type: TOPIC_LOAD_END, topics: data.results }))
    .catch(() => {
      dispatch({ type: TOPIC_LOAD_END, topics: [] });
      if (AS_INSTALLATION) {
        window.setTimeout(() => topicList(dispatch, getState), 1000);
      }
    })
};

const makeTopicSearchKey = (input) => input.replace(/\s/g, '').toLowerCase();

export const topicSelect = (topic) => (dispatch, getState) => {
  dispatch({ type: TOPIC_SELECT, topic: topic });
  
  if (topic) {
    const hastagFilterMode = 'or', 
      state = getState(),
      topicSearchKey = makeTopicSearchKey(topic.title);
    
    // sets the hashtagFilter 
    hashtagFilterSet(topic.hashtags, hastagFilterMode)(dispatch, getState);
    
    // sets the clippath for the graph
    // assumes the name of the clippath and topic (without spaces) are the same
    const clipPathKey = state.coOccurence.clipPaths.findIndex((clipPath) => makeTopicSearchKey(clipPath.name) == topicSearchKey)
    coOccurenceGraphClipPathSet(clipPathKey)(dispatch, getState);
  }
}

export const topicClear = () => (dispatch, getState) => {
  dispatch({ type: TOPIC_CLEAR });
  dispatch(coOccurenceGraphClipPathClear());
}