import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NetworkGraph from '../../components/NetworkGraph';
import * as networkGraphActions from '../../actions/network';
import { hashtagFilterAdd } from '../../actions/hashtagFilter';

function mapStateToProps(state) {
  return { 
    ...state.network,
    activeGraph: state.app.activeGraph,
    hashtagFilter: state.hashtagFilter
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...networkGraphActions, hashtagFilterAdd }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkGraph);
