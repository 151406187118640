export const API_URL = (process.env.NODE_ENV === 'development') ? "http://localhost:8000/api/" : "/api/";
export const INITIAL_HASHTAGS = (process.env.NODE_ENV === 'development') ? [
  {
    id: 1,
    text: 'amazon'
  }
] : [
  {
    id: 156,
    text: 'amazonía'
  }
];
export const AS_INSTALLATION = false;