import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HashtagFilter from '../../components/HashtagFilter';
import * as hashtagFilterActions from '../../actions/hashtagFilter';
import * as timeFilterActions from '../../actions/timeFilter';

function mapStateToProps(state) {
  return {
    ...state.hashtagFilter,
    coOccurence: state.coOccurence
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(hashtagFilterActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HashtagFilter);
