import {
  HASHTAG_NETWORK_LOAD_END
} from '../actions/network'

const initialState = {
  hashtags: [],
  links: []
}

export default function network (state = initialState, action) {
  switch (action.type) {
    case HASHTAG_NETWORK_LOAD_END:
      return {
        ...state,
        hashtags: action.hashtags,
        links: action.links
      }

    default:
      return state;
  }
}