import {
  FREQUENCY_LOAD_END
} from '../actions/frequency'

import { makeDate } from '../services/date';

/* 

  Frequency: { date: Date, count: integer} 

  frequency: Array<Frequency{ date: Date, count: integer}>
*/

const initialState = {
  frequency: []
}


export default function frequency (state = initialState, action) {
  switch (action.type) {
    case FREQUENCY_LOAD_END:
      return {
        ...state,
        frequency: action.frequency.map((r) => ({ ...r, 'date': makeDate(r.date) }))
      }

    default:
      return state;
  }
}