import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import HashtagFilter from '../hashtagFilter'
import TimeFilter from '../timeFilter'
import HashtagSearch from '../hashtagSearch'
import CoOccurenceGraph from '../coOccurenceGraph'
import NetworkGraph from '../networkGraph'
import FrequencyGraph from '../frequencyGraph';
import ActiveGraphSwitch from '../activeGraphSwitch';
import StatusList from '../statusList';
import Navbar from '../navbar';
import Pages from '../pages';
import Exporter from '../exporter';

export const App = () => (
  <>
    {/* <nav> */}
      {/* <NavLink exact to="/">Automatic generation</NavLink> */}
      {/* <NavLink to="/manual">Manual fragment selection</NavLink> */}
    {/* </nav> */}
    {/* <Route exact path="/" component={ AutoEditorView } /> */}
    {/* <Route path="/manual" component={ EditorView } /> */}
    <Navbar />
    <Pages />
    <main>
      <section class="graphs">
        <ActiveGraphSwitch />
        <CoOccurenceGraph />
        <NetworkGraph />
      </section>
      <StatusList />
    </main>
    <section id="controls">
      <HashtagFilter />
      {/* <section className="control--spacer"></section> */}
      <HashtagSearch />
    </section>
    <section>
      <TimeFilter />
    </section>
    <FrequencyGraph />
  </>
)

export default App;