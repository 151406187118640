import React from 'react';
import { formatDate } from '../services/date';

export const TimeFilter = ({ start, end, timeFilterClear  }) => (
  <>
    { start && end && 
      <section className="control--panel time-filter"> 
        between { formatDate(start) } and { formatDate(end) }
        <span className="button" onClick={ () => timeFilterClear() }>Clear time filter</span>
      </section>
    }
  </>
)

export default TimeFilter