import { API_URL } from '../settings';

export const api_url = (tail) => {
  return `${API_URL}${tail}`;
}

const get = (url, data) => {
  if (data) {
    const URLData = new URLSearchParams(data);
    url += '?' + URLData.toString();
  }

  const promise = new Promise((resolve, reject) => {
    fetch(api_url(url), {
      method: "GET"
    }).then((response) => {
      if (response.ok) {
          response.json()
          .then(resolve)
          .catch(reject);
      }
      else {
        reject();
      }
    }).catch(reject);
  });

  return promise;
}


const _post = (url, data) => {
  const promise = new Promise((resolve, reject) => {
    const postData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          data[key].forEach((v) => postData.append(key, v));
        } else {
          postData.append(key, data[key]);
        }
      }
    }

    fetch(api_url(url), {
      method: "POST",
      body: postData
    }).then(resolve).catch(reject);
  });

  return promise;
}

// TODO generalize API call function
const post = (url, data) => {
  const promise = new Promise((resolve, reject) => {
    _post(url, data).then((response) => {
      if (response.ok) {
        response.json()
          .then((data) => resolve(data.results))
          .catch(reject);
      } else {
        reject();
      }
    }).catch(reject);
  });

  return promise;
}

function toDateString (d) {
  if (d && d instanceof Date) {
    return `${ d.getFullYear() }-${ d.getMonth() + 1 }-${ d.getDate() }`;
  }

  return null;
}

export const statusSearch = (hashtags, start, end, mode, page) => get('statuses/search', { query: hashtags.map(h => h.id).join(','), start: toDateString(start), end: toDateString(end), mode: mode, page: page })

export const topicList = () => get('topics/', { format: 'json' })

export const hashtagSearch = (query, startswith) => get('hashtags/', { format: 'json', query, startswith })

export const hashtagCoOccurence = (hashtags, start, end, mode) => get('hashtags/cooccurence', { query: hashtags.map(h => h.id).join(','), start: toDateString(start), end: toDateString(end), mode: mode})

export const hashtagFrequency = (hashtags, start, end, mode) => get('hashtags/frequency', { query: hashtags.map(h => h.id).join(','), start: toDateString(start), end: toDateString(end), mode: mode })

export const hashtagNetwork = (hashtags, start, end, mode) => get('hashtags/network', { query: hashtags.map(h => h.id).join(','), start: toDateString(start), end: toDateString(end), mode: mode })

export const pageList = () => get('pages/', { format: 'json' })

export const pageContent = (id) => get(`pages/${ id }/`, { format: 'json' })