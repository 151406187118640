import { triggerDownload } from '../services/file'

const { polygon } = require('@jscad/csg/src/api/primitives2d-api');
const CSG = require('@jscad/csg/src/core/CSG');
const { serialize, CSGPolygontoStlString } = require('@jscad/stl-serializer/CSGToStla');

/*
state {
  ...
  coOccurence: {
    ...
    hashtags: Array<{ id: integer, text: string, count: integer }>
    polygons: Array<{ points: Array<[float, float]>, text: string, count: integer, center: Array<[float, float]>}>
  },
  frequency: {
    frequency: Array<Frequency{ date: Date, count: integer}>
  }
}
*/

export const exportFrequencies = () => (_, getState) => {
  const state = getState();
  const frequencies = state.frequency.frequency.map(f => f.count);
  const max = Math.max.apply(this, frequencies);
  // const data = frequencies.map(f => f / max);
  const data = state.frequency.frequency.map(f => [f.date, f.count / max]);
  triggerDownload(JSON.stringify(data), 'frequency.json', 'application/json');
};

export const exportCoOccurences = () => (_, getState) => {
  const state = getState();
  const occurences = state.coOccurence.hashtags.map(h => h.count);
  const max = Math.max.apply(this, occurences);
  // const data = occurences.map(o => o / max);
  const data = state.coOccurence.hashtags.map(h => [ h.text, h.count / max]);
  triggerDownload(JSON.stringify(data), 'coccurence.json', 'application/json');
};

export const exportPolygonJSON = () => (_, getState) => {
  const state = getState();
  triggerDownload(JSON.stringify(state.coOccurence.polygons), 'polygons.json', 'application/json');
}

/**
{
  "type": "Feature",
  "geometry": {
    "type": "Polygon",
    "coordinates": [
      [
        [100.0, 0.0], [101.0, 0.0], [101.0, 1.0], [100.0, 1.0],
        [100.0, 0.0]
      ]
    ]
  },
  "properties": {
    "prop1": {
      "this": "that"
    },
    "prop0": "value0"
  }
}
*/
export const exportGeoJSON = () => (_, getState) => {
  const state = getState();
  const data = {
      "type": "FeatureCollection",
      "features": state.coOccurence.polygons.map(p => ({
        "type": "Feature", 
        "geometry": {
          "type": "Polygon",
          "coordinates": [ p.points ]
        },
        "properties": {
          "count": p.count,
          "text": p.text
        }
      }))
  }

  triggerDownload(JSON.stringify(data), 'geojson.json', 'application/json');
}

export const exportSTL = () => (_, getState) => {
  const state = getState(),
        polygons = state.coOccurence.polygons.map(p => (
    polygon({ 
      points: p.points
    }).extrude({ offset: [0,0, Math.log(p.count)]})) //.extrude({ offset: [0,0, Math.log10(p.count) * 100]}))
  );

//   let c = new CSG();
// // let p2 = csg.CSG.polygon({ points: [ [-10,0],[30,0],[30,30] ] });
// // let p3 = csg.CSG.polygon({ points: [ [-20,-20], [-20, -50], [-35, -35] ] });

//   let p1 = polygon({ points: [ [-20,-20], [-20, -50], [-35, -35] ] }).extrude({ offset: [0,0,10]});
//   let p2 = polygon({ points: [ [-10,0],[30,0],[30,30] ] }).extrude({ offset: [0,0,10]});

  // let all = new CSG();

  let result = 'solid csg.js\n'

  polygons.forEach(p => {
    let txt = serialize(p);
    result += txt[0].replace('solid csg.js\n', '').replace('endsolid csg.js\n', '')
  });

  result += 'endsolid csg.js\n'

  triggerDownload(result, 'shapes.stl', 'application/stl');

  // console.log(serialize(p1.union(p2))[0]);
}

export const exportSTLOneSolid = () => (_, getState) => {
  const state = getState(),
        polygons = state.coOccurence.polygons.map(p => (
    polygon({ 
      points: p.points
    }).extrude({ offset: [0,0, Math.log(p.count)]})) //.extrude({ offset: [0,0, Math.log10(p.count) * 100]}))
  );

  let c = new CSG();

  polygons.forEach(p => {
    c = c.union(p)
  });

  triggerDownload(serialize(c), 'shapes.stl', 'application/stl');

  // console.log(serialize(p1.union(p2))[0]);
}

export const exportCSV = () => (_, getState) => {
  const state = getState();
  let data = 'Text,Count,X,Y\n';
  state.coOccurence.polygons.forEach(p => {
    data += `${p.text},${p.count},${p.center[0]},${p.center[1]}\n`;
  });

  triggerDownload(data, 'voronoi.csv', 'text/csv');
}