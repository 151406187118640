import {
  HASTAG_FILTER_ADD,
  HASHTAG_FILTER_REMOVE,
  HASHTAG_FILTER_RESET,
  HASHTAG_FILTER_SET,
  HASHTAG_FILTER_MODE_SET
} from '../actions/hashtagFilter'

import { INITIAL_HASHTAGS } from '../settings'

const initialState = {
  mode: 'or',
  hashtags: INITIAL_HASHTAGS
}

// Adds entry yo list, only if there isn't an
// entry in the list with the given key yet.  
function extendUnique(list, entry, key) {
  const exists = list.find((e) => e[key] == entry[key]);

  if (! exists) {
    return [ entry ].concat(list);
  }
  return list;
}

export default function hashtagFilter (state = initialState, action) {
  switch (action.type) {
    case HASTAG_FILTER_ADD:
      return {
        ...state,
        hashtags: extendUnique(state.hashtags, action.hashtag, 'id')
      }

    case HASHTAG_FILTER_REMOVE:
      return {
        ...state,
        hashtags: state.hashtags.filter((hashtag) => hashtag.id !== action.hashtag.id)
      }

      

    case HASHTAG_FILTER_RESET:
      return {
        ...state,
        hashtags: INITIAL_HASHTAGS
      }

    case HASHTAG_FILTER_SET:
      return {
        ...state,
        hashtags: action.hashtags
      }

    case HASHTAG_FILTER_MODE_SET:
      return {
        ...state,
        mode: action.mode
      }

    default:
      return state;
  }
}