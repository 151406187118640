import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { AS_INSTALLATION } from '../settings';


export class Navbar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount () {
    if (this.props.pages === null) {
      this.props.loadPageList();
    }
  }

  render = () => {
    return <nav>
      <header>
        <h1>Rivers // Amazonia geo-linguistics (v0.3)</h1>
      </header>
      <ul>
        { this.props.pages !== null && this.props.pages.map((page) => {
          if (page.id != 'INSTAGRAM' && page.id != 'TWITTER') {
            if (page.url) {
              if (!AS_INSTALLATION) {
                return <li><a href={ page.url } target="_blank">{ page.navbar_label }</a></li>
              }
            }
            else {
              return <li><a href="" onClick={ (e) => { e.preventDefault(); this.props.showPage(page.id) } }>{ page.navbar_label }</a></li>
            }
          }
        })}
        {!AS_INSTALLATION && <>
          <li><a href="https://www.instagram.com/laura.c.guerra/" target="_blank"><FontAwesomeIcon icon={ faInstagram } size="lg" /></a></li>
          <li><a href="https://twitter.com/AMAZON_NATION" target="_blank"><FontAwesomeIcon icon={ faTwitter } size="lg" /></a></li>
        </>}
        {AS_INSTALLATION && <>
          <li><a href="" onClick={ (e) => { e.preventDefault(); this.props.showPage('INSTAGRAM') } } target="_blank"><FontAwesomeIcon icon={ faInstagram } size="lg" /></a></li>
          <li><a href="" onClick={ (e) => { e.preventDefault(); this.props.showPage('TWITTER') } } target="_blank"><FontAwesomeIcon icon={ faTwitter } size="lg" /></a></li>
        </>}
      </ul>
    </nav>
  }
}

export default Navbar;