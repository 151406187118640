import React from 'react';
import { messages } from  '../messages';
import { AS_INSTALLATION } from '../settings';

export class TopicList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount () {
    if (!this.props.topics) {
      this.props.topicList();
    }
  }

  // render = () => {
  //   return <>
  //     { this.props.topics && 
  //       <section className="control--panel topic--list" { ...{ title: (AS_INSTALLATION) ? null : messages.topicList.tooltip, 'data-as-installation': AS_INSTALLATION } }>
  //         Select tweets by topic:
  //         { this.props.topics.map((topic) => <section key={ topic.id } className="topic--list--topic"
  //             onClick={ () => this.props.hashtagFilterSet(topic.hashtags, 'or') }>
  //             { topic.title }
  //             <section className="topic--list--topic-hashtag--list">
  //             { topic.hashtags.map((hashtag) => <section key={ hashtag.id } className="topic--list--topic-hashtag--list--hashtag">#{ hashtag.text }</section> ) }
  //             </section>
  //           </section> ) }
  //       </section>
  //     }
  //   </>
  // }

  render = () => {
    return <>
      { this.props.topics && 
        <section className="control--panel topic--list" { ...{ title: (AS_INSTALLATION) ? null : messages.topicList.tooltip, 'data-as-installation': AS_INSTALLATION } }>
          <label>
            <select onChange={ (e) => {
                const nextTopicIndex = parseInt(e.target.value);

                if (nextTopicIndex > -1) {
                  this.props.topicSelect(this.props.topics[nextTopicIndex]);
                }
                else {
                  this.props.topicSelect(null);
                }
                }}>
              <option { ... { value: -1, selected: (this.props.selected == -1) } }>Select tweets by topic</option>
              { this.props.topics.map((topic, idx) => (
                <option { ... { value: idx,  title: topic.hashtags.map(h => '#' + h.text).join(', ') , selected: (this.props.selected == topic) } }>{ topic.title }</option>
                )) }
            </select>
          </label>
        </section>
      }
    </>
  }
}

export default TopicList