import React, { createRef } from 'react';
import * as d3 from 'd3';
import { voronoiMapSimulation, d3VoronoiMapError } from 'd3-voronoi-map';
import { GRAPH_COOCCURENCE } from '../reducers/app';
import * as Raphael from 'raphael';
// import { precisionFixed } from 'd3';
import { debounced } from '../services/utils';
import Exporter from '../containers/exporter';
import { messages } from '../messages';
import { style } from 'd3';
import { AS_INSTALLATION } from '../settings';
import TopicList from '../containers/topicList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

function voronoiMap (data, clipPath, svgEl, hashtagFilterAdd, coOccurenceGraphPolygonsSet, iteration) {
  if (iteration == undefined) {
    iteration = 0;
  }

  let clippedPadding = 10;

  if (svgEl) {
    if (data.length > 0) {
      console.log('Starting voronoi draw')
      let clientRect = svgEl.parentElement.getBoundingClientRect();
      let scale, clip, viewport;

      const width = clientRect.width,
            height = clientRect.height;

      if (clipPath) {
        let p = Raphael.parsePathString(clipPath.path);
        let abs = Raphael._pathToAbsolute(p);
  
        let points = abs.map(s => [s[1]*10, s[2]*10]).filter(p => p[0] != undefined && p[1] != undefined),
            x = points.map(p => p[0]),
            y = points.map(p => p[1]),
            minX = Math.min.apply(this, x),
            minY = Math.min.apply(this, y),
            maxX = Math.max.apply(this, x),
            maxY = Math.max.apply(this, y),
            shapeWidth = maxX - minX,
            shapeHeight = maxY - minY;

        console.log(points);
        console.log('Minx', minX, 'MaxX', maxX);
        console.log('MinY', minY, 'MaxY', maxY);

  
        let screenRatio = width / height,
            shapeRatio = shapeWidth / shapeHeight,
            xPadding = 0,
            yPadding = 0;
  
        // console.log(screenRatio, shapeRatio);
  
        if (screenRatio > shapeRatio) {
          // Screen is wider than shape, pad left and right
          scale = shapeHeight / (height - clippedPadding * 2);
          xPadding = ((screenRatio * shapeHeight) - shapeWidth)
          console.log(shapeWidth, (screenRatio * shapeHeight), xPadding);
        } else {
          // Screen is higher than the shape, pad above and below
          scale = shapeWidth / (width - clippedPadding * 2);
          yPadding = ((shapeWidth / screenRatio) - shapeHeight);
          // console.log(shapeHeight, yPadding);
        }
        
        viewport = [minX - xPadding / 2, minY - yPadding / 2, shapeWidth + xPadding, shapeHeight + yPadding]

        clip = points;

      } else {
        scale = 1;
        clip = [[0, 0], [0, height], [width, height], [width, 0]];
        viewport = [0, 0, width, height]
      }

      const svg = d3.select(svgEl)
              .attr("width", (clipPath) ? width - clippedPadding * 2 : width)
              .attr("height", (clipPath) ? height - clippedPadding * 2 : height)
              .attr("viewBox", viewport)
              .style('font-size', (.7 * scale) + 'rem');
              // .style("font", (9 * scale) + "px sans-serif");
      svg.selectAll('*').remove();

      if (clipPath) {
        console.log(clippedPadding.toString(10) + 'px');
        svg.style('top', clippedPadding.toString(10) + 'px');
        svg.style('right', clippedPadding.toString(10) + 'px');
        svg.style('bottom', clippedPadding.toString(10) + 'px');
        svg.style('left', clippedPadding.toString(10) + 'px');
      } else {
        svg.style('top', '0px');
        svg.style('right', '0px');
        svg.style('bottom', '0px');
        svg.style('left', '0px');
      }
    
      var simulation = voronoiMapSimulation(data)
        .weight( function(d) { return Math.sqrt(d.count); /*getBaseLog(1.5, d.count) */ } )  // set the weight accessor
        .clip(clip)
        .stop();                                                // immediately stops the simulation
    
      var state = simulation.state();                           // retrieve the simulation's state, i.e. {ended, polygons, iterationCount, convergenceRatio}
    
      while (!state.ended) {                                    // manually launch each iteration until the simulation ends
        try {                                    
          simulation.tick();
        }
        catch (e) {
          if (e instanceof d3VoronoiMapError) {
            console.log(e.message);
            console.log('Trying again');
  
            if (iteration < 10) {
              return voronoiMap(data, clipPath, svgEl, hashtagFilterAdd, coOccurenceGraphPolygonsSet, iteration + 1);
            }
            else {
              console.log('Gave up');
              return;
            }
          }
          else {
            throw e;
          }
        }
        state = simulation.state();
      }
    
      var polygons = state.polygons;                            // retrieve polygons, i.e. cells of the final Voronoï map
    
      svg.selectAll('path').data(polygons)                      // d3's join
        .enter()                                                // create cells with appropriate shapes and colors
        .append('path')
        .attr('d', function(d) {
          return d3.line()(d.site.polygon) + 'z';
        })
        .style('fill', 'transparent')
        .style('stroke', '#deffd9')
        .style('stroke-width', scale + 'px');

      var costs = svg.append("g")
        .classed('labels', true)
        .selectAll(".label")
        .data(polygons)
        .enter()
          .append("text")
            .on("click", function (e, d) {
              hashtagFilterAdd({
                id: d.site.originalObject.data.originalData.id,
                text: d.site.originalObject.data.originalData.text
              });
            })
            .classed("label", true)
            .classed("graph--label", true)
            .attr("transform", function(d){
              return "translate("+[d.site.x, d.site.y]+")"; // +6 for centering
            })
            .text(function(d){
              return `#${d.site.originalObject.data.originalData.text}`; // (${d.site.originalObject.data.originalData.count})`;
            })
            .style('text-anchor', 'middle')
            .style('fill', '#deffd9');

      // polygons is an array of arrays
      // with an extra attribute 'site'
      // site
      //    neighbours => interesting for the future ?
      //    originalObject
      //      originalData
      //        data
      //          id
      //          count
      //          text

      const stlPolygons = polygons.map(polygon => {
        return { 'points': polygon.map(p => [p[0]/10, p[1]/10]), 'text': polygon.site.originalObject.data.originalData.text, 'count': polygon.site.originalObject.data.originalData.count, center: [ polygon.site.x/10, polygon.site.y/10 ] };
      });

      coOccurenceGraphPolygonsSet(stlPolygons);
    
      // var labels = treemapContainer.append("g")
      //   .classed('labels', true)
      //   .selectAll(".label")
      //   .data(polygons)
      //   .enter()
      //     .append("g")
      //       .classed("label", true)
      //       .attr("transform", function(d){
      //         return "translate("+[d.polygon.site.x, d.polygon.site.y]+")";
      //       })
      console.log('End voronoi draw')
    } else {
      d3.select(svgEl).selectAll('*').remove()
    }
  }
}

export class Treemap extends React.Component {
  constructor(props) {
    super(props)
    this.graphRef = createRef()
  }

  shouldComponentUpdate (nextProps) {
    if (this.props.data.length != nextProps.data.length
        || this.props.graphsize != nextProps.graphsize
        || this.props.clipPath != nextProps.clipPath
      ) {
      return true;
    }
    return (this.props.data.find((el, k) => el.id != nextProps.data[k].id || el.count != nextProps.data[k].count) !== undefined)
  }

  drawMap() {
    voronoiMap(this.props.data.slice(0, this.props.graphsize), this.props.clipPath, this.graphRef.current, this.props.hashtagFilterAdd, this.props.coOccurenceGraphPolygonsSet);
  }

  componentDidMount () {
    this.drawMap();
    window.addEventListener('resize', this.drawMap.bind(this));
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.drawMap.bind(this));
  }
  
  render () {    
    this.drawMap();
    // const graphSwitchTitle = { title: AS_INSTALLATION ? undefined :  messages.activeGraphSwitch.tooltip };
    return <svg id="cooccurence--graph" ref={ this.graphRef } { ...{ title: (AS_INSTALLATION) ? null : "Shows co occurence of hashtag. The size of the cell is related to the amount of tweets using that hashtag." } }></svg>
  }
}

export class CoOccurenceGraphSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  toggle = () => {
    this.setState({ expanded: !(this.state.expanded) });
  }

  render = () => (
    <section id="graph--settings--more" className="control--more" { ... { 'data-expanded': this.state.expanded } }>
      <button onClick={ () =>  this.toggle() }>
        <FontAwesomeIcon icon={faEllipsisH} />
      </button>  
      <section className="control--more--background" onClick={ (e) => { if (e.target == e.currentTarget) { this.toggle(); } } }></section>
      <section className="control--more--content">
        <section className="control control--graphsize">
          <label { ...{ title: (AS_INSTALLATION) ? null : messages.coOccurenceGraph.graphsize } }>
            Words:
            <input type="range" min={ this.props.graphsizeMin } max={ this.props.graphsizeMax } step="10" value={ this.props.graphsize } onChange={ (e) => this.props.coOccurenceGraphsizeSet(e.target.value) } />
            <input type="number" min={ this.props.graphsizeMin } max={ this.props.graphsizeMax } step="10" value={ this.props.graphsize } size="5" onChange={ (e) => this.props.coOccurenceGraphsizeSet(e.target.value) } />
          </label>
        </section>
        <section className="control control--clippath">
          <label>
            Clip Path:
            <select onChange={ (e) => { this.toggle(); this.props.coOccurenceGraphClipPathSet(parseInt(e.target.value)); } } value={ this.props.selectedClipPathKey }>
              <option value={ -1 }>No clip path</option>
              { this.props.clipPaths.map((clipPath, key) => (
                <option value={ key }>{ clipPath.name }</option>
              )) }
            </select>
          </label>              
        </section>
      </section>
    </section>
  )
}

export class CoOccurenceGraph extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.hashtagCoOccurence(this.props.hashtagFilter.hashtags);
  }

  render = () => {
    if (this.props.activeGraph == GRAPH_COOCCURENCE) {
      if (this.props.loading) {
        return <section id="cooccurence--graph--container" { ...{ title: (AS_INSTALLATION) ? null : messages.coOccurenceGraph.tooltip } } data-loading><span>Loading vocabulary</span></section>;
      } else {
        return <section id="cooccurence--graph--container" { ...{ title: (AS_INSTALLATION) ? null : messages.coOccurenceGraph.tooltip } }>
          <section id="cooccurence--graph--settings">
            <TopicList />
            <CoOccurenceGraphSettings { ...this.props } />
            {!AS_INSTALLATION && <Exporter /> }
          </section>
          <Treemap data={ this.props.hashtags } graphsize={ this.props.graphsize } hashtagFilterAdd={ this.props.hashtagFilterAdd } coOccurenceGraphPolygonsSet={ this.props.coOccurenceGraphPolygonsSet } clipPath={ this.props.selectedClipPath }/>
        </section>
      }
    } else {
      return <></>
    }
  } 
}

export default CoOccurenceGraph