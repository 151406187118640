import { hashtagFrequency as hashtagFrequencyAPI } from '../services/api';
import { AS_INSTALLATION } from '../settings';

export const FREQUENCY_LOAD_START = 'FREQUENCY_LOAD_START'
export const FREQUENCY_LOAD_END = "FREQUENCY_LOAD_END";

export const hashtagFrequency = () => (dispatch, getState) => {
  dispatch({ type: FREQUENCY_LOAD_START })
  hashtagFrequencyAPI(getState().hashtagFilter.hashtags, undefined, undefined, getState().hashtagFilter.mode)
    .then((data) => dispatch({ type: FREQUENCY_LOAD_END, frequency: data.results }))
    .catch(() => {
      dispatch({ type: FREQUENCY_LOAD_END, frequency: [] });
      if (AS_INSTALLATION) {
        window.setTimeout(() => hashtagFrequency(dispatch, getState), 1000);
      }
    })
};
