export const messages = {
  frequencyGraph: {
    tooltip: "Click and drag to filter tweets by time"
  },

  statusList: {
    tooltip: "Lists tweets found with selected filters"
  },

  coOccurenceGraph: {
    tooltip: "Visualizes which hashtags are used in the selected tweets",
    graphsize: "Adjust the amount of words visible in the graph. More is slower.",
  },

  networkGraph: {
    tooltip: "Visualizes how hashtags are used together in the selected tweets"
  },

  activeGraphSwitch: {
    tooltip: "Toggle between co-occurence- and network visualisation"
  },

  hashtagSearch: {
    tooltip: "Search for hashtags to add them to the hashtag filter"
  },

  hashtagFilter: {
    tooltip: "Filter tweets by hashtag"
  },

  topicList: {
    tooltip: "Filter hashtags by loading a predefined search covering a topic"
  },
};