import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// import example from './example';
import hashtagSearch from './hashtagSearch';
import hashtagFilter from './hashtagFilter';
import coOccurence from './coOccurence';
import network from './network';
import frequency from './frequency';
import timeFilter from './timeFilter';
import topic from './topic';
import status from './status';
import pages from './pages';
import app from './app';
// Import reducers

export default function createRootReducer(history: {}) {
  const routerReducer = connectRouter(history);
  // Perhaps remove this last call / fat arrow

  return connectRouter(history)(
    combineReducers({
      router: routerReducer,
      app,
      hashtagSearch,
      hashtagFilter,
      coOccurence,
      frequency,
      timeFilter,
      network,
      topic,
      status,
      pages
      /* , other reducers */ 
    })
  );
}
