import { LOAD_PAGE_CONTENT_END, LOAD_PAGE_LIST_END, SHOW_PAGE, CLOSE_PAGE } from "../actions/pages"
import { AS_INSTALLATION } from "../settings";
import QRInstagram from '../static/images/qr_instagram.png'
import QRTwitter from '../static/images/qr_twitter.png'



/**
 * Page { id: int, url: str|null, navbar_label: str, content: str|null }
 */
const initialState = {
  activePage: null, // Null or pageId
  pages: null, // null|array<Page>
}

export default function pages (state = initialState, action) {
  switch (action.type) {

    case SHOW_PAGE:
      return {
        ...state,
        activePage: action.id
      };
    
    case CLOSE_PAGE:
      return {
        ...state,
        activePage: null
      };
  
    case LOAD_PAGE_CONTENT_END:
      if (action.page) {
        return {
          ...state,
          pages: state.pages.map((page) => {
            if (page.id == action.page.id) {
              if (AS_INSTALLATION) {
                const parser = new DOMParser(),
                      doc = parser.parseFromString(action.page.content, 'text/html');

                let links = doc.querySelectorAll('a');

                for (let l = links.length - 1; l > -1; l--) {
                  for (let n = 0; n < links[l].childNodes.length; n++) {
                    links[l].parentElement.insertBefore(links[l].childNodes[n], links[l]);
                  }
                  links[l].parentElement.removeChild(links[l]);
                }
                action.page.content = doc.body.innerHTML;
              }

              return action.page;
            }
  
            return page;
          })
        }
      }
      else {
        return state
      }

    case LOAD_PAGE_LIST_END:
      return {
        ...state,
        pages: action.pages.concat([
          {
            'id': 'INSTAGRAM',
            'navbar_label': 'instagram',
            'content': `<img src="${ QRInstagram }" />`
          },
          {
            'id': 'TWITTER',
            'navbar_label': 'twitter',
            'content': `<img src="${ QRTwitter }" />`
          },
        ])
      }

    default:
      return state
  }
}