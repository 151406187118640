import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CoOccurenceGraph from '../../components/CoOccurenceGraph';
import * as coOccurenceActions from '../../actions/coOccurence';
import { hashtagFilterAdd } from '../../actions/hashtagFilter';

function mapStateToProps(state) {
  return { 
    ...state.coOccurence,
    activeGraph: state.app.activeGraph,
    hashtagFilter: state.hashtagFilter
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...coOccurenceActions, hashtagFilterAdd }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoOccurenceGraph);
