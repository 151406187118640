export const formatDate = (d) => `${ d.getDate() }-${ d.getMonth() + 1 }-${ d.getFullYear() }`

export const formatTime = (d) => {
  let hours = d.getHours().toString(),
      minutes = d.getMinutes().toString();

  if (hours.length < 2) {
    hours = '0' + hours;
  }

  if (minutes.length < 2) {
    minutes = '0' + minutes;
  }

  return `${ hours }:${ minutes }`;
};

export const formatDateTime = (d) => `${ formatDate(d) } ${ formatTime(d)}`;

const dateRe = /(\d{4})-0?(\d{1,2})-0?(\d{1,2})/;

const dateTimeRe = /(\d{4})-0?(\d{1,2})-0?(\d{1,2})T0?(\d{1,2}):0?(\d{1,2}):0?(\d{1,2})Z/;


/**
 * Transforms a date string in format YYYY-MM-DD into a Date object
 * @param {string} input datestring to transform into a timestamp
 */
export const makeDate = (input) => {
  const result = dateRe.exec(input),
  year = parseInt(result[1]),
  month = parseInt(result[2]),
  date = parseInt(result[3]);

  return new Date(year, month-1, date);
}

/**
 * Transforms a date string in format YYYY-MM-DDThh:mm:ssZ into a Date object
 * @param {string} input datetimestring to transform into a timestamp
 */
export const makeDateTime = (input) => {
  const result = dateTimeRe.exec(input),
  year = parseInt(result[1]),
  month = parseInt(result[2]),
  date = parseInt(result[3]),
  hours = parseInt(result[4]),
  minutes = parseInt(result[5]),
  seconds = parseInt(result[6]);

  return new Date(year, month-1, date, hours, minutes, seconds);
}