import { pageContent, pageList } from "../services/api";
import { AS_INSTALLATION } from "../settings";

export const SHOW_PAGE = 'SHOW_PAGE';
export const CLOSE_PAGE = 'CLOSE_PAGE';
export const LOAD_PAGE_LIST_START = 'LOAD_PAGE_LIST_START';
export const LOAD_PAGE_LIST_END = 'LOAD_PAGE_LIST_END';
export const LOAD_PAGE_CONTENT_START = 'LOAD_PAGE_CONTENT_START';
export const LOAD_PAGE_CONTENT_END = 'LOAD_PAGE_CONTENT_END';

export const showPage = (id) => (dispatch, getState) => {
  let state = getState(),
      page = state.pages.pages.find((page) => page.id == id);

  if (!page || !page.content) {
    loadPageContent(id)(dispatch, getState);
  }

  dispatch({ type: SHOW_PAGE, id: id });
}

export const closePage = () => ({ type: CLOSE_PAGE })

export const loadPageContent = (id) => (dispatch, _) => {
  dispatch({ type: LOAD_PAGE_CONTENT_START })
  pageContent(id)
    .then((data) => dispatch({ type: LOAD_PAGE_CONTENT_END, page: data }))
    .catch(() => dispatch({ type: LOAD_PAGE_CONTENT_END, page: null }))
};

export const loadPageList = () => (dispatch, _) => {
  dispatch({ type: LOAD_PAGE_LIST_START })
  pageList()
    .then((data) => dispatch({ type: LOAD_PAGE_LIST_END, pages: data.results }))
    .catch(() => {
      dispatch({ type: LOAD_PAGE_LIST_END, pages: [] });
      if (AS_INSTALLATION) {
        window.setTimeout(() => loadPageList(dispatch, _), 1000);
      }
    })
};