import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger';
// import createHistory from 'history/createBrowserHistory'
import { createHashHistory } from 'history';
import createRootReducer from './reducers'

export const history = createHashHistory();
const rootReducer = createRootReducer(history);

const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  // Logging Middleware 
  const logger = createLogger({
    level: 'info',
    collapsed: true
  });

  middleware.push(logger);

  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    composedEnhancers
  )
}

// export const { configureStore, history }