import React from 'react';
import { GRAPH_COOCCURENCE, GRAPH_NETWORK} from '../reducers/app';
import { messages} from '../messages';
import { AS_INSTALLATION } from '../settings';

const graphSwitchTitle = { title: AS_INSTALLATION ? undefined :  messages.activeGraphSwitch.tooltip };

export const ActiveGraphSwitch = ({ activeGraph, activeGraphSet }) => (
  <section className="control active-graph--switch" { ...graphSwitchTitle }>
    <section data-active={ (activeGraph == GRAPH_COOCCURENCE) } onClick={ () => activeGraphSet(GRAPH_COOCCURENCE) }>Voronoi (vocabulary)</section>
    <section data-active={ (activeGraph == GRAPH_NETWORK) } onClick={ () => activeGraphSet(GRAPH_NETWORK) }>Network (co-occurrence)</section>
  </section>
)

export default ActiveGraphSwitch;